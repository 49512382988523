import { forwardRef } from 'react';

import classNames from 'classnames';

export const withStyledComponents = (
  mainClassName: string,
  Component: string | any,
  extraClasses?: { [propName: string]: string },
) =>
  forwardRef(({ className, style, children, ...props }: any, ref: any) => {
    const newProps = { ...props };
    let extraClassNames = '';
    if (extraClasses) {
      extraClassNames = Object.keys(extraClasses)
        .map((propName) => {
          delete newProps[propName];
          if (props[propName] !== undefined) {
            return props[propName] ? extraClasses[propName] : '';
          }
          return '';
        })
        .join(' ');
    }

    let currentComponent = Component;

    if (newProps.tag) {
      currentComponent = newProps.tag;
      delete newProps.tag;
    }

    if (typeof currentComponent === 'string') {
      switch (currentComponent) {
        case 'span':
          return (
            <span
              ref={ref}
              className={classNames(mainClassName, extraClassNames, className)}
              style={style}
              {...newProps}
            >
              {children}
            </span>
          );
        case 'form':
          return (
            <form
              ref={ref}
              className={classNames(mainClassName, extraClassNames, className)}
              style={style}
              {...newProps}
            >
              {children}
            </form>
          );
        case 'ul':
          return (
            <ul ref={ref} className={classNames(mainClassName, extraClassNames, className)} style={style} {...newProps}>
              {children}
            </ul>
          );
        case 'li':
          return (
            <li ref={ref} className={classNames(mainClassName, extraClassNames, className)} style={style} {...newProps}>
              {children}
            </li>
          );
        case 'button':
          return (
            <button
              ref={ref}
              className={classNames(mainClassName, extraClassNames, className)}
              style={style}
              {...newProps}
            >
              {children}
            </button>
          );
        case 'label':
          return (
            <label
              ref={ref}
              className={classNames(mainClassName, extraClassNames, className)}
              style={style}
              {...newProps}
            >
              {children}
            </label>
          );
        case 'p':
          return (
            <p ref={ref} className={classNames(mainClassName, extraClassNames, className)} style={style} {...newProps}>
              {children}
            </p>
          );
        case 'input':
          return (
            <input
              ref={ref}
              className={classNames(mainClassName, extraClassNames, className)}
              style={style}
              {...newProps}
            />
          );
        case 'a':
          return (
            <a ref={ref} className={classNames(mainClassName, extraClassNames, className)} style={style} {...newProps}>
              {children}
            </a>
          );
        case 'main':
          return (
            <main
              ref={ref}
              className={classNames(mainClassName, extraClassNames, className)}
              style={style}
              {...newProps}
            >
              {children}
            </main>
          );
        case 'h2':
          return (
            <h2 ref={ref} className={classNames(mainClassName, extraClassNames, className)} style={style} {...newProps}>
              {children}
            </h2>
          );
        case 'h1':
          return (
            <h1 ref={ref} className={classNames(mainClassName, extraClassNames, className)} style={style} {...newProps}>
              {children}
            </h1>
          );
        case 'div':
          return (
            <div
              ref={ref}
              className={classNames(mainClassName, extraClassNames, className)}
              style={style}
              {...newProps}
            >
              {children}
            </div>
          );
        default:
          console.error(`${currentComponent} is not handled`);
          return (
            <div
              ref={ref}
              className={classNames(mainClassName, extraClassNames, className)}
              style={style}
              {...newProps}
            >
              {children}
            </div>
          );
      }
    }

    return (
      <Component
        ref={ref}
        className={classNames(mainClassName, extraClassNames, className)}
        style={style}
        {...newProps}
      >
        {children}
      </Component>
    );
  });
